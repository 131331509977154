import React from 'react';
import { css } from '@emotion/core';

import { FormField, Input, RichTextEditor } from '@myungsoo/components';
import DocumentForm from '@myungsoo/base/components/DocumentForm';

const ArticleForm = ({
  doc: docProp = { title: '', content: '' },
  onSave,
  ...restProps
}) => {
  const renderFields = React.useCallback(({ doc, onUpdate }) => {
    return (
      <>
        <FormField label="Title">
          <Input
            value={doc.title}
            onChange={(event) => onUpdate({ title: event.target.value })}
          />
        </FormField>

        <FormField label="Content">
          <RichTextEditor
            css={css`
              max-height: 70vh;
            `}
            value={doc.content}
            onChange={(content) => onUpdate({ content })}
          />
        </FormField>
      </>
    );
  }, []);

  return (
    <DocumentForm
      doc={docProp}
      renderFields={renderFields}
      onSave={onSave}
      {...restProps}
    />
  );
};

export default ArticleForm;
