import React from 'react';
import { css } from '@emotion/core';

import { Button, Group } from '@myungsoo/components';
import { AdminPageBase, DocumentList } from '@myungsoo/base/components';

import ArticleForm from './ArticleForm';

function Header({ onAdd }) {
  return (
    <div
      css={css`
        text-align: right;
      `}
    >
      <Button onClick={() => onAdd()}>Add</Button>
    </div>
  );
}

function Document({ doc, onClone, onEdit, onRemove }) {
  return (
    <article
      css={css`
        position: relative;
        padding: 1rem 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        :hover {
          background: rgba(255, 255, 255, 0.05);
        }

        .buttons {
          display: none;
        }

        :hover .buttons {
          display: block;
        }

        img {
          max-width: 100%;
        }
      `}
    >
      <h1
        css={css`
          margin: 0;
        `}
      >
        {doc.title}
      </h1>

      <Group
        className="buttons"
        css={css`
          position: absolute;
          top: 0;
          right: 0;
        `}
      >
        <Button onClick={onClone}>Clone</Button>
        <Button onClick={onEdit}>Edit</Button>
        <Button onClick={onRemove}>Remove</Button>
      </Group>
    </article>
  );
}

const News = ({ pageId, config }) => {
  const { DB_ROOT_PATH } = config;

  const [loading, setLoading] = React.useState(false);

  return (
    <AdminPageBase id={pageId} config={config} loading={loading}>
      <hr />

      <DocumentList
        collectionPath={`${DB_ROOT_PATH}/pages/${pageId}/articles`}
        renderHeader={Header}
        renderDocument={Document}
        renderDocumentForm={ArticleForm}
        onLoadingChange={setLoading}
      />
    </AdminPageBase>
  );
};

export default News;
