import React from 'react';

import News from '@myungsoo/base/pages/admin/News';

import AdminLayout from '../../components/AdminLayout';
import Seo from '../../components/Seo';

import * as config from '../../../config';

export default () => (
  <AdminLayout>
    <Seo title="Admin" />
    <News pageId="news" config={config} />
  </AdminLayout>
);
